type Translations = {
	[languageCode: string]: string; // Allows any language code (e.g., "en", "fr", "el")
};

type NestedTranslations = {
	[key: string]: Translations;
};

type LocalizationData = {
	[section: string]: NestedTranslations;
};

export const translations:LocalizationData = {
	"About": {
		"About | Spitakimu": { "el" : "Ποιοί είμαστε | Spitakimu"},
		"Discover Spitakimu, the top real estate search engine in Cyprus. Learn about our mission to connect people with their ideal homes through accurate property listings and expert advice.": { "el" : "Ανακαλύψτε το Spitakimu, την κορυφαία μηχανή αναζήτησης ακινήτων στην Κύπρο. Μάθετε για την αποστολή μας να συνδέουμε τους ανθρώπους με τα ιδανικά τους σπίτια μέσω ακριβών καταχωρήσεων ακινήτων και συμβουλών από ειδικούς."},
		"Making property hunting": { "el" : "Κάνοντας την αναζήτηση ακινήτων"},
		"easier": { "el" : "ευκολότερη"},
		"Spitakimu is a propery finder app on a mission to build tools that help you find the perfect home.": { "el" : "Το Spitakimu είναι μια εφαρμογή εύρεσης ακινήτων με αποστολή να δημιουργήσει εργαλεία που θα σας βοηθήσουν να βρείτε το τέλειο σπίτι."},
		"Fighting": { "el" : "Αγωνιζόμαστε"},
		"for the underdog.": { "el" : "για τα αουτσάιντερ."},
		"We started Spitakimu for one reason: to simplify the real estate process by providing a reliable, transparent, and innovative platform that helps individuals and families find their dream home. We strive to bring buyers, renters, and real estate professionals together in a way that benefits everyone.": { "el" : "Ξεκινήσαμε το Spitakimu για έναν λόγο: να απλοποιήσουμε τη διαδικασία εύρεσης ακινήτου, προσφέροντας μια αξιόπιστη, διαφανή και καινοτόμο πλατφόρμα που βοηθά άτομα και οικογένειες να βρουν το ιδανικό τους σπίτι. Σκοπός μας είναι να φέρουμε σε επαφή αγοραστές, ενοικιαστές και επαγγελματίες με έναν τρόπο που ωφελεί όλους."},
		"We know the": { "el" : "Ξέρουμε"},
		"perfect": { "el" : "το ιδανικό"},
		"home for you": { "el" : "σπίτι για εσάς"},
		"Using data-driven insights, we provide recommendations tailored to your needs and preferences.": { "el" : "Χρησιμοποιώντας πληροφορίες που βασίζονται σε δεδομένα, παρέχουμε συστάσεις προσαρμοσμένες στις ανάγκες και τις προτιμήσεις σας."},
		"The power of": { "el" : "Η δύναμη της"},
		"location": { "el" : "τοποθεσίας"},
		"Choosing the perfect home isn’t just about the property itself—it’s about where it’s located. The right neighborhood can enhance your lifestyle, provide convenience, and even impact the long-term value of your investment.": { "el" : "Η επιλογή του τέλειου σπιτιού δεν αφορά μόνο το ίδιο το ακίνητο, αλλά και την τοποθεσία του. Η σωστή γειτονιά μπορεί να βελτιώσει τον τρόπο ζωής σας, να προσφέρει ευκολία και να επηρεάσει ακόμη και τη μακροπρόθεσμη αξία της επένδυσής σας."},
		"Advanced": { "el" : "Προηγμένα"},
		"search tools": { "el" : "εργαλεία αναζήτησης"},
		"Find properties that match your exact needs with filters for location, price, property type, and more. Whether you're browsing from your laptop or mobile, we make property search effortless.": { "el" : "Βρείτε ακίνητα που ανταποκρίνονται ακριβώς στις ανάγκες σας με φίλτρα για τοποθεσία, τιμή, τύπο ακινήτου και άλλα. Είτε περιηγείστε από το φορητό σας υπολογιστή είτε από το κινητό σας, κάνουμε την αναζήτηση ακινήτων εύκολη."},
		"Draw": { "el" : "Σχεδιάστε"},
		"your search": { "el" : "την περιοχή αναζήτησης"},
		"Use our interactive map to outline your ideal area and find properties within your chosen boundaries—simple, precise, and effortless!": { "el" : "Χρησιμοποιήστε τον διαδραστικό μας χάρτη για να σκιαγραφήσετε την ιδανική σας περιοχή και να βρείτε ακίνητα εντός των ορίων που έχετε επιλέξει - απλά, ακριβή και χωρίς κόπο!"},
		"Our values": { "el" : "Οι αξίες μας"},
		"They serve as a compass guiding who we are, how we work, and what we’re focused on next.": { "el" : "Λειτουργούν ως πυξίδα που καθοδηγεί το ποιοι είμαστε, πώς εργαζόμαστε και σε τι επικεντρωνόμαστε στη συνέχεια."},
		"Transparency": { "el" : "Διαφάνεια"},
		"We believe in providing honest, accurate, and up-to-date information so users can make informed decisions.": { "el" : "Πιστεύουμε στην παροχή ειλικρινών, ακριβών και επικαιροποιημένων πληροφοριών, ώστε οι χρήστες να μπορούν να λαμβάνουν τεκμηριωμένες αποφάσεις."},
		"Innovation": { "el" : "Καινοτομία"},
		"We leverage cutting-edge technology to enhance the property search experience and offer smart tools for buyers and renters.": { "el" : "Αξιοποιούμε την τεχνολογία αιχμής για να βελτιώσουμε την εμπειρία αναζήτησης ακινήτων και να προσφέρουμε έξυπνα εργαλεία για αγοραστές και ενοικιαστές."},
		"User-Centric Approach": { "el" : "Προσέγγιση με επίκεντρο τον χρήστη"},
		"Our platform is designed with our users in mind, making property search intuitive, efficient, and stress-free.": { "el" : "Η πλατφόρμα μας έχει σχεδιαστεί με γνώμονα τους χρήστες μας, καθιστώντας την αναζήτηση ακινήτων διαισθητική, αποτελεσματική και χωρίς άγχος."},
		"Community Focus": { "el" : "Εστίαση στην Κοινότητα"},
		"We are deeply connected to the Cyprus real estate market and aim to foster a strong network of buyers, renters, and agents.": { "el" : "Είμαστε βαθιά συνδεδεμένοι με την Κυπριακή αγορά ακινήτων και στοχεύουμε στην ανάπτυξη ενός ισχυρού δικτύου αγοραστών, ενοικιαστών και πρακτόρων."},
		"Integrity": { "el" : "Ακεραιότητα"},
		"We work only with trusted real estate professionals and ensure a secure environment for property transactions.": { "el" : "Συνεργαζόμαστε μόνο με έμπιστους επαγγελματίες του κλάδου των ακινήτων και εξασφαλίζουμε ένα ασφαλές περιβάλλον για τις συναλλαγές ακινήτων."},
		"Are you a": { "el" : "Είστε"},
		"realtor?": { "el" : "μεσίτης;"},
		"Contact us to get our package deals and learn how you can advertise your property listings on our website.": { "el" : "Επικοινωνήστε μαζί μας για να λάβετε τα πακέτα μας και να μάθετε πώς μπορείτε να διαφημίσετε τις αγγελίες των ακινήτων σας στον ιστότοπό μας."},
		"Register your company": { "el" : "Εγγραφή της εταιρείας σας"},
	},
	"Actions": {
		"Close": { "el" : "Close"},
		"Cancel": { "el" : "Ακύρωση"},
		"Save search": { "el" : "Αποθήκευση αναζήτησης"},
		"Provide a name for your search and save. Next time pick up from where you left off!": { "el" : "Δώστε ένα όνομα για την αναζήτησή σας και αποθηκεύστε. Την επόμενη φορά συνεχίστε από εκεί που σταματήσατε!"},
		"Success! Your search \"": { "el" : "Επιτυχία! Η αναζήτησή σας \""},
		"\" has been saved.": { "el" : "\" έχει αποθηκευτεί."},
		"Manage saved searches": { "el" : "Διαχείριση αποθηκευμένων αναζητήσεων"},
		"Back to search": { "el" : "Επιστροφή στην αναζήτηση"},
		"Name": { "el" : "Όνομα"},
	},
	"Agents": {
		"Back to home": { "el" : "Πίσω στην αρχική σελίδα" },
		"Register real estate": { "el" : "Εγγραφή κτηματομεσίτη"},
		"Your enquiry has been successfully submitted.": { "el" : "Το αίτημά σας υποβλήθηκε με επιτυχία."},
		"Register your company": { "el" : "Δηλώστε την εταιρεία σας"},
		"To gain access to Spitakimu Agent Profile & Dashboard": { "el" : "Για να αποκτήσετε πρόσβαση στο Spitakimu Agent Profile & Dashboard"},
		"Showcase your expertise to active home shoppers in your market. A free Spitakimu agent account helps you build your brand and keep track of your sales.": { "el" : "Παρουσιάστε την τεχνογνωσία σας σε ενεργούς αγοραστές κατοικιών στην αγορά σας. Ένας δωρεάν λογαριασμός πράκτορα στο Spitakimu σας βοηθά να δημιουργήσετε το εμπορικό σας σήμα και να παρακολουθείτε τις πωλήσεις σας."},
		"Agent profile.": { "el" : "Προφίλ κτηματομεσίτη."},
		"A page dedicated fully to your company. Shows contact details and all the information related to you. Includes statistics about the properties and services your offer.": { "el" : "Μια σελίδα αφιερωμένη πλήρως στην εταιρεία σας. Εμφανίζει τα στοιχεία επικοινωνίας και όλες τις πληροφορίες που σχετίζονται με εσάς. Περιλαμβάνει στατιστικά στοιχεία σχετικά με τα ακίνητα και τις υπηρεσίες που προσφέρετε."},
		"Automatic sync.": { "el" : "Αυτόματος συγχρονισμός."},
		"Connect your website to automatically update your property listings. When you add or remove something from your website, we will do the same. Simple as that.": { "el" : "Συνδέστε τον ιστότοπό σας για να ενημερώνετε αυτόματα τις καταχωρίσεις των ακινήτων σας. Όταν προσθέτετε ή αφαιρείτε κάτι από την ιστοσελίδα σας, θα κάνουμε το ίδιο. Τόσο απλά."},
		"Leads statistics.": { "el" : "Στατιστικά leads."},
		"View data about customers viewing requests and phone calls through the agent dashboard.": { "el" : "Προβολή δεδομένων σχετικά με τα αιτήματα προβολής και τις τηλεφωνικές κλήσεις των πελατών μέσω του πίνακα οργάνων του πράκτορα."},
		"Provide your details": { "el" : "Παρέχετε τα στοιχεία σας"},
		"You will hear from our team after we validate your details.": { "el" : "Θα λάβετε νέα από την ομάδα μας αφού επικυρώσουμε τα στοιχεία σας."},
		"Profile": { "el" : "Προφίλ"},
		"This information will be displayed publicly in your agent profile page so be careful what you share.": { "el" : "Αυτές οι πληροφορίες θα εμφανίζονται δημοσίως στη σελίδα του προφίλ του πράκτορά σας, οπότε προσέξτε τι μοιράζεστε."},
		"Company name": { "el" : "Όνομα εταιρείας"},
		"Email": { "el" : "Email"},
		"Street address": { "el" : "Οδός"},
		"City": { "el" : "Πόλη"},
		"Logo": { "el" : "Λογότυπο"},
		"Description": { "el" : "Περιγραφή"},
		"Telephone": { "el" : "Τηλέφωνο"},
		"Brief description for your profile. URLs are hyperlinked.": { "el" : "Σύντομη περιγραφή του προφίλ σας. Οι διευθύνσεις URL συνδέονται με υπερσυνδέσμους."},
		"Website": { "el" : "Ιστοσελίδα"},
		"ZIP / Postal code": { "el" : "ΤΚ /Ταχυδρομικός κώδικας"},
		"State / Province": { "el" : "Πολιτεία / Επαρχία"},
		"Additional Information": { "el" : "Πρόσθετες πληροφορίες"},
		"Extra information that can make your profile more appealing to users.": { "el" : "Πρόσθετες πληροφορίες που μπορούν να κάνουν το προφίλ σας πιο ελκυστικό στους χρήστες."},
		"Company Contact": { "el" : "Επικοινωνία"},
		"First name": { "el" : "Όνομα"},
		"Last name": { "el" : "Επώνυμο"},
		"Contact Telephone": { "el" : "Τηλέφωνο επικοινωνίας"},
		"Company Social Media": { "el" : "Κοινωνικά μέσα της εταιρείας"},
		"Register company": { "el" : "Εγγραφή εταιρείας"},
		"Register your company | Spitakimu": { "el" : "Καταχωρήστε την εταιρεία σας | Spitakimu"},
		"Join our leading real estate property finder in Cyprus! Register your agency today to showcase listings, connect with buyers, and grow your business online. Sign up now!": { "el" : "Γίνετε μέλος του κορυφαίου σαρωτή ακινήτων στην Κύπρο! Καταχωρήστε το γραφείο σας σήμερα για να παρουσιάσετε τα ακίνητα σας, να συνδεθείτε με αγοραστές και να αναπτύξετε την επιχείρησή σας στο διαδίκτυο. Εγγραφείτε τώρα!"},
		"Search for": { "el" : "Αναζήτηση για"},
		"real estate": { "el" : "κτηματομεσίτες"},
		"companies.": { "el" : "."},
		"Search": { "el" : "Αναζήτηση"},
		"Realtor name": { "el" : "Όνομα κτηματομεσίτη"},
		"More info": { "el" : "Περισσότερα"},
		"Call": { "el" : "Κλήση"},
		"properties on Spitakimu": { "el" : "ακίνητα στο Spitakimu"},
		"registered real estate companies": { "el" : "εγγεγραμμένες εταιρείες ακινήτων"},
		"Our realtors are currently feeling a bit shy. Try refreshing the page!": { "el" : "Οι κτηματομεσίτες μας είναι λίγο ντροπαλοί. Δοκιμάστε να ανανεώσετε τη σελίδα!"},
		"No luck, no results found :( Try again maybe with less filters?": { "el" : "Δεν βρέθηκαν αποτελέσματα :( Δοκιμάστε ξανά ίσως με λιγότερα φίλτρα;"},
		"Make sure there is no spelling mistake in the realtor name specified.": { "el" : "Βεβαιωθείτε ότι δεν υπάρχει ορθογραφικό λάθος στο όνομα του μεσίτη που έχει καθοριστεί."},
		"Real estate agents in ${city} | Spitakimu": { "el" : "Κτηματομεσίτες ${city} | Spitakimu"},
		"Discover top real estate agencies in ${city} with Spitakimu. We connect you with trusted professionals to help you find the perfect property.": { "el" : "Ανακαλύψτε τα κορυφαία κτηματομεσιτικά γραφεία ${city} με το Spitakimu. Σας συνδέουμε με αξιόπιστους επαγγελματίες για να σας βοηθήσουμε να βρείτε το τέλειο ακίνητο."},
		"Contact agent request sent": { "el" : "Αποστολή αιτήματος επικοινωνίας σε κτηματομεσίτη"},
		"Your message has been successfully sent.": { "el" : "Το μήνυμά σας στάλθηκε με επιτυχία."},
		"Email agent": { "el" : "Στείλτε email"},
		"Overview": { "el" : "Overview"},
		"Location": { "el" : "Τοποθεσία"},
		"Properties": { "el" : "Ακίνητα"},
		"No. of properties": { "el" : "Αριθμός ακινήτων"},
		"Avg. asking price": { "el" : "Μέση τιμή"},
		"Avg. listing time": { "el" : "Μέσος χρόνος καταχώρισης"},
		"Latest flats and houses for sale": { "el" : "Πρόσφατα διαμερίσματα και σπίτια προς πώληση"},
		"View all flats and houses for sale": { "el" : "Δείτε όλα τα διαμερίσματα και τα σπίτια προς πώληση"},
		"Latest flats and houses to rent": { "el" : "Πρόσφατα διαμερίσματα και σπίτια προς ενοικίαση"},
		"View all flats and houses to rent": { "el" : "Δείτε όλα τα διαμερίσματα και τα σπίτια προς ενοικίαση"},
		"Latest land for sale": { "el" : "Πρόσφατα οικόπεδα προς πώληση"},
		"View all land for sale": { "el" : "Δείτε όλα τα οικόπεδα προς πώληση"},
		"Opening hours": { "el" : "Ώρες λειτουργίας"},
		"Visit agent website": { "el" : "Ιστοσελίδα του μεσίτη"},
		"Looks like the real estate agent you are looking for does not exist.": { "el" : "Φαίνεται ότι ο μεσίτης που ψάχνετε δεν υπάρχει."},
		"Agent's branch": { "el" : "Υποκατάστημα κτηματομεσίτη"},
		"Follow": { "el" : "Ακολουθήστε το"},
		"Find agents": { "el" : "Βρείτε κτηματομεσίτες"},
		"Enter realtor name": { "el" : "Εισάγετε το όνομα του μεσίτη"},
		"Flats and houses for sale": { "el" : "Διαμερίσματα και κατοικίες προς πώληση"},
		"Flats and houses to rent": { "el" : "Διαμερίσματα και κατοικίες προς ενοικίαση"},
		"Land for sale": { "el" : "Οικόπεδα προς πώληση"},
		"- Estate and letting agents | Spitakimu": { "el" : "- Κτηματομεσίτες και ενοικιαστές | Spitakimu"},
		"${name} - Estate and Letting Agents on Spitakimu. View details of ${name}\'s location, listed properties for sale and rent and more.": { "el" : "${name} - Κτηματομεσίτες και ενοικιαστές στο Spitakimu. Δείτε λεπτομέρειες σχετικά με την τοποθεσία του ${name}, τα καταχωρημένα ακίνητα προς πώληση και ενοικίαση και πολλά άλλα."},
		"Send message": { "el" : "Στείλτε μήνυμα"},
		"By submitting this form, you accept our ": { "el" : "Με την υποβολή αυτής της φόρμας, αποδέχεστε τους "},
		"Terms of Use": { "el" : "Όρους Χρήσης"},
		"and ": { "el" : " και "},
		"Privacy Policy": { "el" : "την Πολιτική Απορρήτου"},
		". We process your information to provide the services requested, to improve our products and to personalise your experience.": { "el" : ". Επεξεργαζόμαστε τις πληροφορίες σας για να παρέχουμε τις υπηρεσίες που ζητήσατε, να βελτιώνουμε τα προϊόντα μας και να εξατομικεύουμε την εμπειρία σας."},
	},
	"Auth": {
		"Forgot Password": { "el" : "Ξεχάσατε τον κωδικό πρόσβασής σας;" },
		"No worries, we'll send you reset instructions.": { "el" : "Μην ανησυχείτε, θα σας στείλουμε τις οδηγίες επαναφοράς." },
		"Username or Email": { "el" : "Όνομα χρήστη ή Email" },
		"Recover password": { "el" : "Επαναφορά κωδικού πρόσβασης" },
		"Back to Login": { "el" : "Πίσω στην Είσοδο" },
		"Password Recovery for Spitakimu": { "el" : "Ανάκτηση κωδικού πρόσβασης για το Spitakimu" },
		"Login to Spitakimu": { "el" : "Είσοδος στο Spitakimu" },
		"Welcome back!": { "el" : "Καλώς ήρθατε πάλι!" },
		"Please enter your details.": { "el" : "Παρακαλώ εισάγετε τα στοιχεία σας." },
		"Username": { "el" : "Όνομα χρήστη" },
		"Password": { "el" : "Κωδικός πρόσβασης" },
		"Remember me": { "el" : "Remember me" },
		"Forgot password?": { "el" : "Ξεχάσατε τον κωδικό πρόσβασης;" },
		"Log in": { "el" : "Είσοδος" },
		"Login with a passkey": { "el" : "Σύνδεση με passkey" },
		"Generating Authentication Options": { "el" : "Δημιουργία επιλογών ελέγχου ταυτότητας" },
		"Requesting your authorization": { "el" : "Ζητώντας την εξουσιοδότησή σας" },
		"Verifying your passkey": { "el" : "Επαλήθευση του passkey" },
		"You're logged in! Navigating...": { "el" : "Έχετε συνδεθεί! Πλοήγηση..." },
		"New here?": { "el" : "Καινούργιος εδώ;" },
		"Create an account": { "el" : "Δημιουργία λογαριασμού" },
		"Setup Spitakimu Account": { "el" : "Δημιουργία λογαριασμού Spitakimu" },
		"Welcome": { "el" : "Καλώς ήρθατε" },
		"Thanks for signing up!": { "el" : "Ευχαριστούμε για την εγγραφή σας!" },
		"Welcome aboard": { "el" : "Καλώς ήρθατε" },
		"You can change your photo later": { "el" : "Μπορείτε να αλλάξετε τη φωτογραφία σας αργότερα" },
		"Do you agree to our Terms of Service and Privacy Policy?": { "el" : "Συμφωνείτε με τους Όρους Χρήσης και την Πολιτική Προσωπικών Δεδομένων;" },
		"Reset Password | Spitakimu": { "el" : "Επαναφορά κωδικού πρόσβασης | Spitakimu" },
		"Password Reset": { "el" : "Επαναφορά κωδικού πρόσβασης" },
		"No worries. It happens all the time.": { "el" : "Μην ανησυχείτε. Συμβαίνει συνέχεια." },
		"Reset password": { "el" : "Επαναφορά κωδικού πρόσβασης" },
		"Sign up | Spitakimu": { "el" : "Εγγραφή | Spitakimu" },
		"Create a free Spitakimu account to save your favorite properties, get alerts on new listings, and connect with real estate agents in Cyprus.": { "el" : "Δημιουργήστε δωρεάν λογαριασμό στο Spitakimu για να αποθηκεύσετε τα αγαπημένα σας ακίνητα, να λάβετε ειδοποιήσεις για νέες καταχωρήσεις και να συνδεθείτε με κτηματομεσίτες στην Κύπρο." },
		"Let's start your journey!": { "el" : "Ξεκινήστε το ταξίδι σας!" },
		"Please enter your email.": { "el" : "Παρακαλώ εισάγετε το email σας." },
		"Submit": { "el" : "Submit" },
		"Check your email": { "el" : "Ελέγξτε το email σας" },
		"We've sent you a code to verify your email address.": { "el" : "Σας στείλαμε έναν κωδικό για να επαληθεύσετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας." },
		"Check your 2FA app": { "el" : "Ελέγξτε την εφαρμογή 2FA" },
		"Please enter your 2FA code to verify your identity.": { "el" : "Εισάγετε τον κωδικό 2FA για να επαληθεύσετε την ταυτότητά σας." },
	},
	"Common": {
		"Language": { "el" : "Γλώσσα"},
		"English": { "el" : "Αγγλικά"},
		"UK": { "el" : "ΗΒ"},
		"Greek": { "el" : "Ελληνικά"},
		"GR": { "el" : "ΕΛ"},
		"For sale": { "el" : "Προς πώληση"},
		"To rent": { "el" : "Προς ενοικίαση"},
		"Search": { "el" : "Αναζήτηση"},
		"Searching": { "el" : "Αναζήτηση..."},
		"Enter a city or area": { "el" : "Πληκτρολογήστε μια πόλη ή περιοχή"},
		"Please provide a search area": { "el" : "Παρακαλώ συμπληρώστε μια περιοχή αναζήτησης"},
		"Find agents": { "el" : "Βρείτε μεσίτες"},
		"Map": { "el" : "Χάρτης"},
		"About us": { "el" : "Ποιοί είμαστε"},
		"Find properties for sale in Cyprus": { "el" : "Βρείτε ακίνητα προς πώληση στην Κύπρο"},
		"Find properties to rent in Cyprus": { "el" : "Βρείτε ακίνητα προς ενοικίαση στην Κύπρο"},
		"Favourites": { "el" : "Αγαπημένα" },
		"Saved": { "el" : "Αποθηκευμένο" },
		"Save": { "el" : "Αποθηκεύστε το" },
		"Log in": { "el" : "Είσοδος"},
		"Profile": { "el" : "Προφίλ"},
		"Settings": { "el" : "Ρυθμίσεις"},
		"Logout": { "el" : "Αποσύνδεση"},
		"Houses for sale in Nicosia": { "el" : "Σπίτια προς πώληση στη Λευκωσία"},
		"Houses for sale in Larnaca": { "el" : "Σπίτια προς πώληση στη Λάρνακα"},
		"Houses for sale in Limassol": { "el" : "Σπίτια προς πώληση στη Λεμεσό"},
		"Houses for sale in Paphos": { "el" : "Σπίτια προς πώληση στην Πάφο"},
		"Flats for sale in Egkomi": { "el" : "Διαμερίσματα προς πώληση στην Έγκωμη"},
		"Flats for sale in Agia Zoni": { "el" : "Διαμερίσματα προς πώληση στην Αγία Ζώνη"},
		"Flats for sale in Geroskipou": { "el" : "Διαμερίσματα προς πώληση στη Γεροσκήπου"},
		"Offices for sale in Limassol": { "el" : "Γραφεία προς πώληση στη Λεμεσό"},
		"Land for sale in Paphos": { "el" : "Οικόπεδα προς πώληση στην Πάφο"},
		"Houses to rent in Nicosia": { "el" : "Σπίτια προς ενοικίαση στη Λευκωσία"},
		"Houses to rent in Larnaca": { "el" : "Σπίτια προς ενοικίαση στη Λάρνακα"},
		"Houses to rent in Limassol": { "el" : "Σπίτια προς ενοικίαση στη Λεμεσό"},
		"Houses to rent in Paphos": { "el" : "Σπίτια προς ενοικίαση στην Πάφο"},
		"Flats to rent in Egkomi": { "el" : "Διαμερίσματα προς ενοικίαση στην Egkomi"},
		"Flats to rent in Agia Zoni": { "el" : "Διαμερίσματα προς ενοικίαση στην Αγία Ζώνη"},
		"Flats to rent in Geroskipou": { "el" : "Διαμερίσματα προς ενοικίαση στην Γεροσκήπου"},
		"Offices to rent in Limassol": { "el" : "Γραφεία προς ενοικίαση στη Λεμεσό"},
		"Land to rent in Paphos": { "el" : "Οικόπεδο προς ενοικίαση στην Πάφο"},
		"Buy": { "el" : "Αγορά"},
		"Flats for sale in Limassol": { "el" : "Διαμερίσματα προς πώληση στη Λεμεσό"},
		"Offices for sale in Larnaca": { "el" : "Γραφεία προς πώληση στη Λάρνακα"},
		"Rent": { "el" : "Ενοικίαση"},
		"Flats to rent in Limassol": { "el" : "Διαμερίσματα προς ενοικίαση στη Λεμεσό"},
		"Offices to rent in Paphos": { "el" : "Γραφεία προς ενοικίαση στην Πάφο"},
		"Offices to rent in Larnaca": { "el" : "Γραφεία προς ενοικίαση στη Λάρνακα"},
		"Register real estate": { "el" : "Εγγραφή κτηματομεσίτη"},
		"Register user": { "el" : "Εγγραφή"},
		"Company": { "el" : "Εταιρεία"},
		"© 2025 Spitakimu.com by APG - All rights reserved.": { "el" : "© 2025 Spitakimu.com by APG - Όλα τα δικαιώματα διατηρούνται."},
		"Privacy": { "el" : "Προσωπικά Δεδομένα"},
		"Cookies Policy": { "el" : "Πολιτική Cookies"},
		"Contact us": { "el" : "Επικοινωνήστε μαζί μας"},
		"About Spitakimu": { "el" : "Σχετικά με το Spitakimu"},
		"Terms": { "el" : "Όροι Χρήσης"},
		"Member terms": { "el" : "Όροι Χρήσης Μελών"},
		"e.g. Paphos or Egkomi, Nicosia": { "el" : "π.χ. Πάφος ή Έγκωμη, Λευκωσία"}
	},
	"Listings": {
		"For sale": { "el" : "Προς πώληση" },
		"To rent": { "el" : "Προς ενοικίαση" },
		"Listed by": { "el" : "Καταχωρήθηκε από" },
		"The property you are looking for has been sold, try searching for something similar?": { "el" : "Το ακίνητο που ψάχνετε έχει πωληθεί, δοκιμάστε να ψάξετε για κάτι παρόμοιο;" },
		"Take me back to the home page": { "el" : "Επιστροφή στην αρχική σελίδα" },
		"You have already requested a viewing for this property.": { "el" : "Έχετε ήδη ζητήσει ραντεβού για αυτό το ακίνητο." },
		"Viewing Request Sent": { "el" : "Αίτημα γισ ραντεβού στάλθηκε" },
		"Your enquiry has been successfully sent.": { "el" : "Το αίτημά σας στάλθηκε με επιτυχία." },
		"Would you like to view this property?": { "el" : "Θα θέλατε να δείτε αυτό το ακίνητο;" },
		"Send enquiry": { "el" : "Αποστολή αιτήματος" },
		"By submitting this form, you accept our ": { "el" : "Με την υποβολή αυτής της φόρμας, αποδέχεστε τους " },
		"Terms of Use": { "el" : "Όρους Χρήσης" },
		" and ": { "el" : " και την " },
		"Privacy Policy": { "el" : "Πολιτική Προστασίας Προσωπικών Δεδομένων" },
		". We process your information to provide the services requested, to improve our products and to personalise your experience.": { "el" : ". Επεξεργαζόμαστε τις πληροφορίες σας για να παρέχουμε τις υπηρεσίες που ζητήσατε, να βελτιώνουμε τα προϊόντα μας και να εξατομικεύουμε την εμπειρία σας." },
		"Morning": { "el" : "Πρωί" },
		"Afternoon": { "el" : "Απόγευμα" },
		"Evening": { "el" : "Βράδυ" },
		"Book viewing | Spitakimu": { "el" : "Κλείστε ραντεβού | Spitakimu" },
		"Schedule a property viewing easily with Spitakimu. Fill out our quick form to book a visit and explore your future home or investment property today!": { "el" : "Κλείστε ραντεβού για ακίνητα εύκολα με το Spitakimu. Συμπληρώστε τη γρήγορη φόρμα μας για να κλείσετε μια επίσκεψη και να εξερευνήσετε το μελλοντικό σας σπίτι ή το επενδυτικό σας ακίνητο σήμερα!" },
		"Book viewing": { "el" : "Κλείστε ραντεβού" },
		"Contact": { "el" : "Στείλε μήνυμα" },
		"Add to favourites": { "el" : "Προσθήκη στα Αγαπημένα" },
		"Remove from favourites": { "el" : "Αφαίρεση από τα Αγαπημένα" },
		"bed": { "el" : "κρεβάτι" },
		"bath": { "el" : "μπάνιο" },
		"beds": { "el" : "κρεβάτια" },
		"baths": { "el" : "μπάνια" },
		"No listing with id \"": { "el" : "Δεν υπάρχει ακίνητο με id \"" },
		"\" exists": { "el" : "\"" },
		"View all agent's properties": { "el" : "Δείτε όλα τα ακίνητα του μεσίτη" },
		"Pool": { "el" : "Πισίνα" },
		"Fireplace": { "el" : "Τζάκι" },
		"Garden": { "el" : "Κήπος" },
		"Heating": { "el" : "Θέρμανση" },
		"Underfloor heating": { "el" : "Υποδαπέδια θέρμανση" },
		"Air condition": { "el" : "Κλιματιστικό" },
		"Balcony": { "el" : "Μπαλκόνι" },
		"Parking": { "el" : "Χώρος στάθμευσης" },
		"Points of interest": { "el" : "Σημεία ενδιαφέροντος" },
		"Features and description": { "el" : "Περιγραφή" },
		"Exact location for this listing is not publicly available. Contact the real estate company for more details.": { "el" : "Η ακριβής τοποθεσία για αυτή την καταχώριση δεν είναι διαθέσιμη στο κοινό. Επικοινωνήστε με την εταιρεία ακινήτων για περισσότερες λεπτομέρειες." },
		"The price shown above is the asking price (VAT not included). The property is simply advertised on Spitakimu.com, contact the real estate company for further details.": { "el" : "Η τιμή που αναφέρεται παραπάνω είναι η ζητούμενη τιμή (χωρίς ΦΠΑ). Το ακίνητο απλά διαφημίζεται στο Spitakimu.com, επικοινωνήστε με την κτηματομεσιτική εταιρεία για περισσότερες λεπτομέρειες." },
		"These distances are calculated in a straight line. The actual route and distance may vary.": { "el" : "Οι αποστάσεις αυτές υπολογίζονται σε ευθεία γραμμή. Η πραγματική διαδρομή και απόσταση μπορεί να διαφέρει." },
		"Similar properties": { "el" : "Παρόμοια ακίνητα" },
		"Contact agent": { "el" : "Επικοινωνήστε με τον μεσίτη" },
		"Hide description": { "el" : "Απόκρυψη περιγραφής" },
		"See full description": { "el" : "Δείτε όλη την περιγραφή" },
		"Properties": { "el" : "Ακίνητα" },
		"Houses": { "el" : "Σπίτια" },
		"Businesses": { "el" : "Επιχειρήσεις" },
		"Flats": { "el" : "Διαμερίσματα" },
		"Shops, showrooms": { "el" : "Καταστήματα, εκθεσιακοί χώροι" },
		"Restaurants, bars": { "el" : "Εστιατόρια, μπαρ" },
		"Land": { "el" : "Οικόπεδα" },
		"Storage": { "el" : "Αποθήκες" },
		"Offices": { "el" : "Γραφεία" },
		"Rooms, flatmates": { "el" : "Δωμάτια, συγκατοίκοι" },
		"Other": { "el" : "Άλλο" },
		"Residential buildings": { "el" : "Οικιστικά κτίρια" },
		"Listing reference": { "el" : "Παραπομπή ακίνητου" },
		"Date added": { "el" : "Ημερομηνία προσθήκης" },
		"Property type": { "el" : "Τύπος ακινήτου" },
		"Furnishing": { "el" : "Επίπλωση" },
		"Fully furnished": { "el" : "Πλήρως επιπλωμένο" },
		"Semi furnished": { "el" : "Ημι-επιπλωμένο" },
		"Unfurnished": { "el" : "Μη επιπλωμένο" },
		"Appliances οnly": { "el" : "Οικιακές συσκευές μόνο" },
		"Energy efficiency": { "el" : "Ενεργειακή απόδοση" },
		"Not provided": { "el" : "Μη καταχωρημένο" },
		"Plot type": { "el" : "Τύπος οικοπέδου" },
		"Residential": { "el" : "Οικιστικό οικόπεδο" },
		"Agricultural": { "el" : "Αγροτεμάχιο" },
		"Commercial": { "el" : "Εμπορικό οικόπεδο" },
		"Industrial": { "el" : "Βιομηχανικό οικόπεδο" },
		"Tourist": { "el" : "Τουριστικό οικόπεδο" },
		"Coverage": { "el" : "Κάλυψη" },
		"Density": { "el" : "Πυκνότητα" },
		"Parcel number": { "el" : "Αριθμός τεμαχίου" },
		"Planning zone": { "el" : "Πολεοδομική ζώνη" },
		"Property condition": { "el" : "Κατάσταση ακινήτου" },
		"Newly built": { "el" : "Νεόκτιστο" },
		"Under construction": { "el" : "Υπό κατασκευή" },
		"${title} for €${price}. Property sold by ${name}. See property details on Spitakimu.": { "el" : "${title} για €${price}. Το ακίνητο πωλείται από τον ${name}. Δείτε λεπτομέρειες σχετικά με το ακίνητο στο Spitakimu" },
	},
	"Map": {
		"bed": { "el" : "κρεβάτι" },
		"bath": { "el" : "μπάνιο" },
		"beds": { "el" : "κρεβάτια" },
		"baths": { "el" : "μπάνια" },
		"More info": { "el" : "Περισσότερες πληροφορίες" },
		"Saved": { "el" : "Αποθηκευμένο" },
		"Save": { "el" : "Αποθηκεύστε το" },
		"Are you sure you want to start a new search?": { "el" : "Είστε σίγουροι ότι θέλετε να ξεκινήσετε μια νέα αναζήτηση;" },
		"You are about to start a new search. Do you want to discard the current search?": { "el" : "Πρόκειται να ξεκινήσετε μια νέα αναζήτηση. Θέλετε να απορρίψετε την τρέχουσα αναζήτηση;" },
		"Discard & New search": { "el" : "Απόρριψη & νέα αναζήτηση" },
		"Cancel & Keep search": { "el" : "Ακύρωση & διατήρηση αναζήτησης" },
		"Are you sure you want to exit?": { "el" : "Είστε σίγουροι ότι θέλετε να βγείτε από τη λειτουργία επεξεργασίας;" },
		"Exiting edit mode will discard all the shapes created without searching.": { "el" : "Η έξοδος από τη λειτουργία επεξεργασίας θα απορρίψει όλα τα σχήματα που δημιουργήθηκαν χωρίς αναζήτηση." },
		"Discard & Exit": { "el" : "Απόρριψη & έξοδος" },
		"Stay & Keep shapes": { "el" : "Μείνετε & κρατήστε σχήματα" },
		"Shapes are too large!": { "el" : "Τα σχήματα είναι υπερβολικά μεγάλα!" },
		"You need to delete or fix the invalid shapes before searching for properties.": { "el" : "Πρέπει να διαγράψετε ή να διορθώσετε τα άκυρα σχήματα πριν αναζητήσετε ακίνητα." },
		"You cannot just search for the whole island! Please select a smaller area.": { "el" : "Δεν μπορείτε απλά να ψάξετε σε όλο το νησί! Παρακαλούμε επιλέξτε μια μικρότερη περιοχή." },
		"Select a shape first!": { "el" : "Επιλέξτε ένα σχήμα πρώτα!" },
		"You cannot delete shapes without selecting at least one.": { "el" : "Δεν μπορείτε να διαγράψετε σχήματα χωρίς να επιλέξετε τουλάχιστον ένα." },
		"Search this area": { "el" : "Αναζήτηση σε αυτή την περιοχή" },
		"Start from scratch": { "el" : "Ξεκινήστε από την αρχή" },
		"Save this search": { "el" : "Αποθηκεύστε αυτή την αναζήτηση" },
		"How to use the map?": { "el" : "Πώς να χρησιμοποιήσετε τον χάρτη;" },
		"The area is too large!": { "el" : "Η περιοχή είναι πολύ μεγάλη!" },
		"Zoom in to a more specific area and try again.": { "el" : "Κάντε μεγέθυνση σε μια πιο συγκεκριμένη περιοχή και δοκιμάστε ξανά." },
		"List view": { "el" : "Προβολή σε λίστα" },
		"Edit area": { "el" : "Edit" },
		"More": { "el" : "Περισσότερα" },
		"Exit": { "el" : "Έξοδος" },
		"Delete": { "el" : "Διαγραφή" },
		"Select a drawing tool to start creating your first shape": { "el" : "Επιλέξτε ένα εργαλείο σχεδίασης για να σχεδιάσετε το πρώτο σας σχήμα" },
		"Click on the map to start drawing a shape": { "el" : "Κάντε κλικ στον χάρτη για να ξεκινήσετε να σχεδιάζετε ένα σχήμα" },
		"Click on a shape to select and modify it": { "el" : "Κάντε κλικ σε ένα σχήμα για να το επιλέξετε" },
		"Drag the corners to edit the search area": { "el" : "Σύρετε τις γωνίες για επεξεργασία" },
		"Select": { "el" : "Επιλέξτε" },
		"Draw shape": { "el" : "Σχεδιάστε σχήμα" },
		"Draw freehand": { "el" : "Σχεδιάστε ελεύθερα" },
		"Draw circle": { "el" : "Σχεδιάστε κύκλο" },
		"Search properties": { "el" : "Αναζήτηση ακινήτων" },
		"Start your search": { "el" : "Ξεκινήστε την αναζήτησή σας" },
		"No properties found": { "el" : "Δεν βρέθηκαν ακίνητα" },
		"Create a shape first!": { "el" : "Δημιουργήστε πρώτα ένα σχήμα!" },
		"You cannot search without any shapes.": { "el" : "Δεν μπορείτε να ψάξετε χωρίς σχήματα." },
		"Map Search | ${pt1} ${st} in ${q} | Spitakimu": { "el" : "Αναζήτηση χάρτη | ${pt1} ${st} ${q} | Spitakimu" },
		"Find the best ${pt2} ${st} in ${q}. Browse Cyprus' largest real estate database for exclusive listings.": { "el" : "Βρείτε το καλύτερο ${pt2} ${st} στην περιοχή ${q}. Περιηγηθείτε στη μεγαλύτερη βάση δεδομένων ακινήτων της Κύπρου για αποκλειστικές καταχωρήσεις." },
		"Map Search | Spitakimu": { "el" : "Αναζήτηση με χάρτη | Spitakimu" },
		"Find properties in Cyprus with our advanced map search. Draw multiple areas and discover the best real estate listings available.": { "el" : "Βρείτε ακίνητα στην Κύπρο με την προηγμένη αναζήτηση στον χάρτη μας. Σχεδιάστε πολλαπλές περιοχές και ανακαλύψτε τις καλύτερες διαθέσιμες αγγελίες ακινήτων." },
		"Property": { "el" : "Ακίνητα" },
		"property": { "el" : "ακίνητα" },
		"for sale": { "el" : "προς πώληση" },
		"to rent": { "el" : "προς ενοικίαση" },
		"properties found": { "el" : "ακίνητα βρέθηκαν" },
		"Filter results": { "el" : "Φίλτρα" },
		"Filters": { "el" : "Φίλτρα" },
		"Filter your results": { "el" : "Φιλτράρετε τα αποτελέσματά σας" },
		"Basic": { "el" : "Βασικά" },
		"Advanced": { "el" : "Περισσότερ" },
	},
	"Marketing": {
		"Contact Us | Spitakimu": { "el" : "Επικοινωνήστε μαζί μας | Spitakimu" },
		"Have questions about buying, selling, or renting property in Cyprus? Contact Spitakimu today and let our experts assist you with your real estate needs.": { "el" : "Έχετε ερωτήσεις σχετικά με την αγορά, πώληση ή ενοικίαση ακινήτων στην Κύπρο; Επικοινωνήστε με την Spitakimu σήμερα και αφήστε τους ειδικούς μας να σας βοηθήσουν με τις κτηματομεσιτικές σας ανάγκες." },
		"Contact us request sent!": { "el" : "Επικοινωνήστε μαζί μας το αίτημα απεστάλη!" },
		"Your enquiry has been successfully submitted.": { "el" : "Το αίτημά σας υποβλήθηκε με επιτυχία." },
		"Contact us": { "el" : "Επικοινωνήστε μαζί μας" },
		"Need Assistance? Our team is ready to guide you through your real estate journey.": { "el" : "Χρειάζεστε βοήθεια; Η ομάδα μας είναι έτοιμη να σας καθοδηγήσει στο ταξίδι σας στην αγορά ακινήτων." },
		"Submit request": { "el" : "Υποβολή αιτήματος" },
		"Property for sale | Flats and houses for sale in Cyprus - Spitakimu": { "el" : "Ακίνητα προς πώληση | Διαμερίσματα και κατοικίες προς πώληση στην Κύπρο - Spitakimu" },
		"Find property for sale at Spitakimu - Cyprus's leading online property market resource. Discover flats and houses for sale from the top estate agents and developers.": { "el" : "Βρείτε ακίνητα προς πώληση στο Spitakimu - η κορυφαία διαδικτυακή πηγή της αγοράς ακινήτων στην Κύπρο. Ανακαλύψτε διαμερίσματα και σπίτια προς πώληση από τους κορυφαίους κτηματομεσίτες και κατασκευαστές." },
		"Properties for sale": { "el" : "Ακίνητα προς πώληση" },
		"Search flats and houses for sale in Cyprus.": { "el" : "Αναζήτηση διαμερισμάτων και κατοικιών προς πώληση στην Κύπρο." },
		"Property to rent | Flats and houses to rent in Cyprus - Spitakimu": { "el" : "Ακίνητα προς ενοικίαση | Διαμερίσματα και κατοικίες προς ενοικίαση στην Κύπρο - Spitakimu" },
		"Find property to rent at Spitakimu - Cyprus's leading online property market resource. Discover flats and houses to rent from the top estate agents and developers.": { "el" : "Βρείτε ακίνητα προς ενοικίαση στο Spitakimu - την κορυφαία διαδικτυακή πηγή της αγοράς ακινήτων στην Κύπρο. Ανακαλύψτε διαμερίσματα και σπίτια προς ενοικίαση από τους κορυφαίους κτηματομεσίτες και κατασκευαστές." },
		"Properties to rent": { "el" : "Ακίνητα προς ενοικίαση" },
		"Search flats and houses to rent in Cyprus.": { "el" : "Αναζήτηση διαμερισμάτων και κατοικιών προς ενοικίαση στην Κύπρο." },
		"Spitakimu | Find Your Perfect Home in Cyprus - Property Listings & Rentals": { "el" : "Spitakimu | Βρείτε το τέλειο σπίτι για εσάς στην Κύπρο - Eνοικιάσεις & πωλήσεις ακινήτων" },
		"Search for property with Cyprus' top real estate platform. Discover available houses and apartments for sale or rent, and connect with local estate agents in your area.": { "el" : "Αναζητήστε ακίνητα με την κορυφαία πλατφόρμα ακινήτων της Κύπρου. Ανακαλύψτε διαθέσιμα σπίτια και διαμερίσματα προς πώληση ή ενοικίαση και συνδεθείτε με τοπικούς κτηματομεσίτες στην περιοχή σας." },
		"Cause we know the ": { "el" : "Γιατί ξέρουμε το " },
		"perfect home": { "el" : "ιδανικό σπίτι" },
		" for you.": { "el" : " για εσάς." },
		"Find properties for sale and to rent all over Cyprus.": { "el" : "Βρείτε ακίνητα προς πώληση και ενοικίαση σε όλη την Κύπρο." },
		"Please provide a search area": { "el" : "Παρακαλώ συμπληρώστε μια περιοχή αναζήτησης" },
	},
	"Me": {
		"Joined": { "el" : "Έγινε μέλος" },
		"Logout": { "el" : "Αποσύνδεση" },
		"Edit profile": { "el" : "Επεξεργασία προφίλ" },
		"Saved listings": { "el" : "Αποθηκευμένα ακίνητα" },
		"Saved searches": { "el" : "Αποθηκευμένες αναζητήσεις" },
		"See all": { "el" : "Δείτε όλα" },
		"What is your current property goal?": { "el" : "Ποιος είναι ο στόχος σας τώρα;" },
		"Buy a property": { "el" : "Αγορά ακινήτου" },
		"Rent a property": { "el" : "Ενοικίαση ακινήτου" },
		"Sell a property": { "el" : "Πώληση ακινήτου" },
		"Check the value of my home": { "el" : "Να ελέξω την αξία του σπιτιού μου" },
		"Other": { "el" : "Άλλο" },
		"Next": { "el" : "Επόμενο" },
		"No user with the username \"": { "el" : "Δεν υπάρχει χρήστης με το όνομα χρήστη \"" },
		"\" exists": { "el" : "\"" },
		"My situation": { "el" : "Η κατάστασή μου" },
		"Welcome back!": { "el" : "Καλώς ήρθατε!" },
		"Here's a list of your saved listings and searches!": { "el" : "Εδώ είναι μια λίστα με τις αποθηκευμένες λίστες και αναζητήσεις σας!" },
		"Listings": { "el" : "Ακίνητα" },
		"Searches": { "el" : "Αναζητήσεις" },
		"No searches saved": { "el" : "Δεν υπάρχουν αποθηκευμένες αναζητήσεις" },
		"Hit the ❤︎︎ to save your favourite searches and continue your search faster next time.": { "el" : "Πατήστε την ❤︎︎ για να αποθηκεύσετε τις αγαπημένες σας αναζητήσεις και να συνεχίσετε την αναζήτησή σας πιο γρήγορα την επόμενη φορά." },
		"Start searching": { "el" : "Ξεκινήστε την αναζήτηση" },
		"No listings saved": { "el" : "Δεν υπάρχουν αποθηκευμένα ακίνητα" },
		"Hit the ❤︎︎ to save your favourite listings and never lose your discoveries.": { "el" : "Πατήστε την ❤︎︎ για να αποθηκεύσετε τα αγαπημένα σας ακίνητα και να μην χάσετε ποτέ τις ανακαλύψεις σας." },
		"searches saved": { "el" : "αποθηκευμένες αναζητήσεις" },
		"Are you absolutely sure?": { "el" : "Είστε απολύτως σίγουρος;" },
		"This action cannot be undone. This will permanently delete this saved search from your account.": { "el" : "Αυτή η ενέργεια δεν μπορεί να αναιρεθεί. Αυτό θα διαγράψει μόνιμα αυτή την αποθηκευμένη αναζήτηση από το λογαριασμό σας." },
		"Cancel": { "el" : "Άκυρο" },
		"Delete": { "el" : "Διαγραφή" },
		"Load search": { "el" : "Φόρτωση αναζήτησης" },
		"Delete search": { "el" : "Διαγραφή αναζήτησης" },
		"Setup alerts": { "el" : "Ρύθμιση ειδοποιήσεων" },
		"listings saved": { "el" : "αποθηκευμένα ακίνητα" },
		"This action cannot be undone. This will permanently delete this saved listing from your account.": { "el" : "Αυτή η ενέργεια δεν μπορεί να αναιρεθεί. Αυτό θα διαγράψει μόνιμα αυτο το αποθηκευμένο ακίνητο από το λογαριασμό σας." },
		"Your Favourites | Spitakimu": { "el" : "Τα αγαπημένα σας | Spitakimu" },
		"View your saved properties on Spitakimu. Compare homes for sale and rent across Cyprus, and keep track of your favourite listings.": { "el" : "Δείτε τα αποθηκευμένα ακίνητά σας στο Spitakimu. Συγκρίνετε σπίτια προς πώληση και ενοικίαση σε όλη την Κύπρο και παρακολουθήστε τις αγαπημένες σας καταχωρήσεις." },
	},
	"Profile": {
		"Profile": { "el" : "Προφίλ" },
		"Scan this QR code with your authenticator app.": { "el" : "Σαρώστε αυτόν τον κωδικό QR με την εφαρμογή ελέγχου ταυτότητας." },
		"If you cannot scan the QR code, you can manually add this account to your authenticator app using this code:": { "el" : "Εάν δεν μπορείτε να σαρώσετε τον κωδικό QR, μπορείτε να προσθέσετε χειροκίνητα αυτόν τον λογαριασμό στην εφαρμογή ελέγχου ταυτότητας χρησιμοποιώντας αυτόν τον κωδικό:" },
		"Once you've added the account, enter the code from your authenticator app below. Once you enable 2FA, you will need to enter a code from your authenticator app every time you log in or perform important actions. Do not lose access to your authenticator app, or you will lose access to your account.": { "el" : "Αφού προσθέσετε το λογαριασμό, εισαγάγετε τον κωδικό από την εφαρμογή ελέγχου ταυτότητας που χρησιμοποιείτε παρακάτω. Μόλις ενεργοποιήσετε τον 2FA, θα πρέπει να εισάγετε έναν κωδικό από την εφαρμογή ελέγχου ταυτότητας κάθε φορά που συνδέεστε ή εκτελείτε σημαντικές ενέργειες. Μην χάσετε την πρόσβαση στην εφαρμογή αυθεντικοποιητή σας, διαφορετικά θα χάσετε την πρόσβαση στο λογαριασμό σας." },
		"Submit": { "el" : "Υποβολή" },
		"Cancel": { "el" : "Ακύρωση" },
		"You have enabled two-factor authentication.": { "el" : "Έχετε ενεργοποιήσει τον έλεγχο 2FA." },
		"Disable 2FA": { "el" : "Απενεργοποίηση 2FA" },
		"You have not enabled two-factor authentication yet.": { "el" : "Δεν έχετε ενεργοποιήσει ακόμα τον έλεγχο 2FA." },
		"Two factor authentication adds an extra layer of security to your account. You will need to enter a code from an authenticator app like": { "el" : "Ο έλεγχος ταυτότητας δύο παραγόντων προσθέτει ένα επιπλέον επίπεδο ασφάλειας στο λογαριασμό σας. Θα χρειαστεί να εισαγάγετε έναν κωδικό από μια εφαρμογή ελέγχου ταυτότητας όπως" },
		"to log in.": { "el" : "για να συνδεθείτε." },
		"Enable 2FA": { "el" : "Ενεργοποίηση 2FA" },
		"Disabling two factor authentication is not recommended. However, if you would like to do so, click here:": { "el" : "Η απενεργοποίηση του ελέγχου ταυτότητας δύο παραγόντων δεν συνιστάται. Ωστόσο, αν θέλετε να το κάνετε, κάντε κλικ εδώ:" },
		"Are you sure?": { "el" : "Είστε σίγουρος;" },
		"Image size must be less than 3MB": { "el" : "Το μέγεθος της εικόνας πρέπει να είναι μικρότερο από 3MB" },
		"Image is required": { "el" : "Απαιτείται εικόνα" },
		"Change": { "el" : "Αλλαγή" },
		"Save Photo": { "el" : "Αποθήκευση φωτογραφίας" },
		"Delete": { "el" : "Διαγραφή" },
		"Incorrect password.": { "el" : "Λανθασμένος κωδικός πρόσβασης." },
		"Password is too common": { "el" : "Ο κωδικός πρόσβασης είναι πολύ συνηθισμένος" },
		"Password Changed": { "el" : "Αλλαγή κωδικού πρόσβασης" },
		"Your password has been changed.": { "el" : "Ο κωδικός πρόσβασής σας έχει αλλάξει." },
		"Current Password": { "el" : "Τρέχων Κωδικός Πρόσβασης" },
		"New Password": { "el" : "Νέος Κωδικός Πρόσβασης" },
		"Confirm New Password": { "el" : "Επιβεβαίωση Νέου Κωδικού Πρόσβασης" },
		"Change Password": { "el" : "Αλλαγή κωδικού" },
		"Create Password": { "el" : "Δημιουργία κωδικού" },
		"No passkeys registered yet": { "el" : "Δεν έχουν καταχωρηθεί ακόμη passkeys" },
		"Register new passkey": { "el" : "Καταχώρηση νέου passkey" },
		"Change email from": { "el" : "Αλλαγή email από" },
		"2FA is enabled": { "el" : "Το 2FA είναι ενεργοποιημένο" },
		"Manage connections": { "el" : "Διαχείριση συνδέσεων" },
		"Manage passkeys": { "el" : "Διαχείριση passkeys" },
		"Download your data": { "el" : "Κατεβάστε τα δεδομένα σας" },
		"Delete all your data": { "el" : "Διαγράψτε όλα τα δεδομένα σας" },
		"Here are your current connections:": { "el" : "Εδώ είναι οι τρέχουσες συνδέσεις σας:" },
		"You don't have any connections yet.": { "el" : "Δεν έχετε ακόμη συνδέσεις." },
		"Disconnect this account": { "el" : "Αποσυνδέστε αυτόν τον λογαριασμό" },
		"You cannot delete your last connection unless you have a password.": { "el" : "Δεν μπορείτε να διαγράψετε την τελευταία σας σύνδεση, εκτός αν έχετε κωδικό πρόσβασης." },
		"This email is already in use.": { "el" : "Αυτό το email χρησιμοποιείται ήδη." },
		"Change Email": { "el" : "Αλλαγή email" },
		"You will receive an email at the new email address to confirm.": { "el" : "Θα λάβετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου στη νέα διεύθυνση ηλεκτρονικού ταχυδρομείου για επιβεβαίωση." },
		"An email notice will also be sent to your old address": { "el" : "Μια ειδοποίηση ηλεκτρονικού ταχυδρομείου θα σταλεί επίσης στην παλιά σας διεύθυνση." },
		"New Email": { "el" : "Νέο email" },
		"Send Confirmation": { "el" : "Αποστολή επιβεβαίωσης" },
		"Account Settings | Spitakimu": { "el" : "Ρυθμίσεις λογαριασμού | Spitakimu" },
		"Manage your Spitakimu account settings to customize your property search preferences. Get the most out of your Cyprus real estate journey.": { "el" : "Διαχειριστείτε τις ρυθμίσεις του λογαριασμού σας στο Spitakimu για να προσαρμόσετε τις προτιμήσεις αναζήτησης ακινήτων. Αξιοποιήστε στο έπακρο το ταξίδι σας στα κυπριακά ακίνητα." },
	},
	"Search": {
		"results": { "el" : "ακίνητα" },
		"No luck, no results found :( Try again maybe with less filters?": { "el" : "Δεν βρέθηκαν αποτελέσματα :( Δοκιμάστε ξανά ίσως με λιγότερα φίλτρα;" },
		"We update with real estates daily to make sure every property in the market is available to you.": { "el" : "Ενημερωνόμαστε καθημερινά για να βεβαιωθούμε ότι κάθε ακίνητο στην αγορά είναι διαθέσιμο σε εσάς." },
		"Make sure there is no spelling mistake in the search area specified. Try using the suggestions provided!": { "el" : "Βεβαιωθείτε ότι δεν υπάρχει ορθογραφικό λάθος στην καθορισμένη περιοχή αναζήτησης. Δοκιμάστε να χρησιμοποιήσετε κάτι απο τις συστάσεις!" },
		"Start again": { "el" : "Ξεκινήστε από την αρχή" },
		"for sale": { "el" : "προς πώληση" },
		"to rent": { "el" : "προς ενοικίαση" },
		"Properties": { "el" : "Ακίνητα" },
		"in": { "el" : "στην περιοχή" },
		"Share results": { "el" : "Μοιράσου τα αποτελέσματα" },
		"Send this link to the people you want to share the results with.": { "el" : "Στείλτε αυτόν τον σύνδεσμο στα άτομα με τα οποία θέλετε να μοιραστείτε τα αποτελέσματα." },
		"Draw your search": { "el" : "Σχεδιάστε την περιοχή αναζήτησης" },
		"Explore agencies": { "el" : "Εξερευνήστε κτηματομεσίτες" },
		"Filters": { "el" : "Φίλτρα" },
		"Advanced filters": { "el" : "Προηγμένα φίλτρα" },
		"Added to site": { "el" : "Προστέθηκε στο site" },
		"Heating / Cooling": { "el" : "Θέρμανση / Κλιματισμός" },
		"Property condition": { "el" : "Κατάσταση ακινήτου" },
		"Extras": { "el" : "Extras" },
		"This is not the right way to search for properties.": { "el" : "Αυτός δεν είναι ο σωστός τρόπος αναζήτησης ακινήτων." },
		"Back to home": { "el" : "Πίσω στην αρχική σελίδα" },
		"Map view": { "el" : "Προβολή χάρτη" },
		"Search for": { "el" : "Αναζητήστε" },
		"Browse ${resultCount} verified ${propType} ${saleType} in ${searchTerm}. All listings on Spitakimu.com come from licensed real estate agents. Use smart filters to explore apartments, studios, maisonettes, and detached homes": { "el" : "Περιηγηθείτε ${resultCount} καταχωρήσεις για ${propType} ${saleType} in ${searchTerm}.  Όλες οι καταχωρήσεις στο Spitakimu.com προέρχονται από αδειοδοτημένους μεσίτες ακινήτων. Χρησιμοποιήστε έξυπνα φίλτρα για να εξερευνήσετε διαμερίσματα, στούντιο, μεζονέτες και ανεξάρτητες κατοικίες ανά τιμή, τύπο και χαρακτηριστικά." },
		"on Spitakimu.com and set your own criteria, such as maximum price or minimum bedrooms.": { "el" : "στο Spitakimu.com και ορίστε τα δικά σας κριτήρια, όπως μέγιστη τιμή ή ελάχιστα υπνοδωμάτια." },
	},
	"Sitemap": {
		"The sitemap of our website showing all the possible pages you can navigate to. A list of all the areas based on cities in Cyprus is available for easy navigation.": { "el" : "To sitemap της ιστοσελίδας μας που δείχνει όλες τις πιθανές σελίδες στις οποίες μπορείτε να πλοηγηθείτε. Υπάρχει ένας κατάλογος όλων των περιοχών με βάση τις πόλεις της Κύπρου για εύκολη πλοήγηση." },
		"Properties for sale in Famagusta | Spitakimu": { "el" : "Ακίνητα προς πώληση στην Αμμόχωστο | Spitakimu" },
		"A list of all the areas in Famagusta, Cyprus. Explore properties by selecting the desired area.": { "el" : "Ένας κατάλογος όλων των περιοχών στην Αμμόχωστο, Κύπρος. Εξερευνήστε τα ακίνητα επιλέγοντας την επιθυμητή περιοχή." },
		"Properties to rent in Famagusta | Spitakimu": { "el" : "Ακίνητα προς ενοικίαση στην Αμμόχωστο | Spitakimu" },
		"Properties for sale in all areas of Famagusta": { "el" : "Ακίνητα προς πώληση σε όλες τις περιοχές της Αμμοχώστου" },
		"Properties to rent in all areas of Famagusta": { "el" : "Ακίνητα προς ενοικίαση σε όλες τις περιοχές της Αμμοχώστου" },
		"Properties for sale in Larnaca | Spitakimu": { "el" : "Ακίνητα προς πώληση στη Λάρνακα | Spitakimu" },
		"A list of all the areas in Larnaca, Cyprus. Explore properties by selecting the desired area.": { "el" : "Ένας κατάλογος όλων των περιοχών στη Λάρνακα, Κύπρος. Εξερευνήστε τα ακίνητα επιλέγοντας την επιθυμητή περιοχή." },
		"Properties to rent in Larnaca | Spitakimu": { "el" : "Ακίνητα προς ενοικίαση στη Λάρνακα | Spitakimu" },
		"Properties for sale in all areas of Larnaca": { "el" : "Ακίνητα προς πώληση σε όλες τις περιοχές της Λάρνακας" },
		"Properties to rent in all areas of Larnaca": { "el" : "Ακίνητα προς ενοικίαση σε όλες τις περιοχές της Λάρνακας" },
		"Properties for sale in Limassol | Spitakimu": { "el" : "Ακίνητα προς πώληση στη Λεμεσό | Spitakimu" },
		"A list of all the areas in Limassol, Cyprus. Explore properties by selecting the desired area.": { "el" : "Ένας κατάλογος όλων των περιοχών στη Λεμεσό, Κύπρος. Εξερευνήστε τα ακίνητα επιλέγοντας την επιθυμητή περιοχή." },
		"Properties to rent in Limassol | Spitakimu": { "el" : "Ακίνητα προς ενοικίαση στη Λεμεσό | Spitakimu" },
		"Properties for sale in all areas of Limassol": { "el" : "Ακίνητα προς πώληση σε όλες τις περιοχές της Λεμεσού" },
		"Properties to rent in all areas of Limassol": { "el" : "Ακίνητα προς ενοικίαση σε όλες τις περιοχές της Λεμεσού" },
		"Properties for sale in Nicosia | Spitakimu": { "el" : "Ακίνητα προς πώληση στη Λευκωσία | Spitakimu" },
		"A list of all the areas in Nicosia, Cyprus. Explore properties by selecting the desired area.": { "el" : "Κατάλογος όλων των περιοχών στη Λευκωσία, Κύπρος. Εξερευνήστε τα ακίνητα επιλέγοντας την επιθυμητή περιοχή." },
		"Properties to rent in Nicosia | Spitakimu": { "el" : "Ακίνητα προς ενοικίαση στη Λευκωσία | Spitakimu" },
		"Properties for sale in all areas of Nicosia": { "el" : "Ακίνητα προς πώληση σε όλες τις περιοχές της Λευκωσίας" },
		"Properties to rent in all areas of Nicosia": { "el" : "Ακίνητα προς ενοικίαση σε όλες τις περιοχές της Λευκωσίας" },
		"Properties for sale in Paphos | Spitakimu": { "el" : "Ακίνητα προς πώληση στην Πάφο | Spitakimu" },
		"A list of all the areas in Paphos, Cyprus. Explore properties by selecting the desired area.": { "el" : "Κατάλογος όλων των περιοχών στην Πάφο, Κύπρος. Εξερευνήστε τα ακίνητα επιλέγοντας την επιθυμητή περιοχή." },
		"Properties to rent in Paphos | Spitakimu": { "el" : "Ακίνητα προς ενοικίαση στην Πάφο | Spitakimu" },
		"Properties for sale in all areas of Paphos": { "el" : "Ακίνητα προς πώληση σε όλες τις περιοχές της Πάφου" },
		"Properties to rent in all areas of Paphos": { "el" : "Ακίνητα προς ενοικίαση σε όλες τις περιοχές της Πάφου" },
		"Quickly navigate our website with this complete sitemap.": { "el" : "Περιηγηθείτε γρήγορα στον ιστότοπό μας με αυτό το πλήρης sitemap." },
		"Find direct links to all pages for easy access to the content you need.": { "el" : "Βρείτε άμεσους συνδέσμους προς όλες τις σελίδες για εύκολη πρόσβαση στο περιεχόμενο που χρειάζεστε." },
		"Register your company": { "el" : "Εγγραφή της εταιρείας σας" },
		"Sign up": { "el" : "Εγγραφή" },
		"Search by city": { "el" : "Αναζήτηση ανά πόλη" },
		"View all areas": { "el" : "Δείτε όλες τις περιοχές" },
		"For sale in Famagusta": { "el" : "Προς πώληση στην Αμμόχωστο" },
		"For sale in Larnaca": { "el" : "Προς πώληση στη Λάρνακα" },
		"For sale in Limassol": { "el" : "Προς πώληση στη Λεμεσό" },
		"For sale in Nicosia": { "el" : "Προς πώληση στη Λευκωσία" },
		"For sale in Paphos": { "el" : "Προς πώληση στην Πάφο" },
		"To rent in Famagusta": { "el" : "Προς ενοικίαση στην Αμμόχωστο" },
		"To rent in Larnaca": { "el" : "Προς ενοικίαση στη Λάρνακα" },
		"To rent in Limassol": { "el" : "Προς ενοικίαση στη Λεμεσό" },
		"To rent in Nicosia": { "el" : "Προς ενοικίαση στη Λευκωσία" },
		"To rent in Paphos": { "el" : "Προς ενοικίαση στην Πάφο" },
		"in": { "el" : "στην περιοχή" },	
	},
	"Tos": {
		"Terms of service | Spitakimu": { "el" : "Terms of service | Spitakimu" },
		"Spitakimu Terms of Service: This service is operated by Spitakimu. By using our website, apps, or services, these terms apply to you.": { "el" : "Όροι χρήσης του Spitakimu: Αυτή η υπηρεσία λειτουργεί από την Spitakimu. Χρησιμοποιώντας την ιστοσελίδα, τις εφαρμογές ή τις υπηρεσίες μας, οι παρόντες όροι ισχύουν για εσάς." },
		"Terms of service": { "el" : "Όροι Χρήσης" },
		"Welcome to Spitakimu! These terms govern your access to and use of our website, services, and platform.": { "el" : "Καλώς ήρθατε στο Spitakimu! Αυτοί οι όροι διέπουν την πρόσβασή σας και τη χρήση της ιστοσελίδας μας, των υπηρεσιών και της πλατφόρμας μας." },
		"By using Spitakimu, you agree to comply with these terms.": { "el" : "Χρησιμοποιώντας το Spitakimu, συμφωνείτε να συμμορφώνεστε με αυτούς τους όρους." },
		"Acceptance of Terms": { "el" : "Αποδοχή των όρων" },
		"By accessing or using Spitakimu, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree, please do not use our services.": { "el" : "Με την πρόσβαση ή χρήση του Spitakimu, αναγνωρίζετε ότι έχετε διαβάσει, κατανοήσει και αποδεχτεί αυτούς τους Όρους. Αν δεν συμφωνείτε, παρακαλούμε να μην χρησιμοποιείτε τις υπηρεσίες μας." },
		"Our Services": { "el" : "Οι Υπηρεσίες Μας" },
		"Spitakimu provides an online platform that aggregates real estate listings in Cyprus, helping users search, compare, and connect with property sellers, landlords, and agents.": { "el" : "Το Spitakimu παρέχει μια διαδικτυακή πλατφόρμα που συγκεντρώνει καταχωρήσεις ακινήτων στην Κύπρο, βοηθώντας τους χρήστες να αναζητούν, να συγκρίνουν και να επικοινωνούν με πωλητές ακινήτων, ιδιοκτήτες και μεσίτες." },
		"User Accounts": { "el" : "Λογαριασμοί Χρηστών" },
		"You may be required to create an account to access certain features.": { "el" : "Ίσως σας ζητηθεί να δημιουργήσετε λογαριασμό για να αποκτήσετε πρόσβαση σε ορισμένες λειτουργίες." },
		"You are responsible for maintaining the confidentiality of your account credentials.": { "el" : "Είστε υπεύθυνοι για τη διατήρηση της εμπιστευτικότητας των στοιχείων σύνδεσης του λογαριασμού σας." },
		"You agree not to share your account details or allow unauthorized access.": { "el" : "Συμφωνείτε να μην κοινοποιείτε τα στοιχεία του λογαριασμού σας ή να επιτρέπετε μη εξουσιοδοτημένη πρόσβαση." },
		"Listing and Content": { "el" : "Καταχωρήσεις και Περιεχόμενο" },
		"Property listings and related content are provided by third-party real estate agents and property owners.": { "el" : "Οι καταχωρήσεις ακινήτων και το σχετικό περιεχόμενο παρέχονται από τρίτους μεσίτες και ιδιοκτήτες ακινήτων." },
		"We do not verify the accuracy, completeness, or legality of listings and are not liable for any misrepresentations.": { "el" : "Δεν επαληθεύουμε την ακρίβεια, την πληρότητα ή τη νομιμότητα των καταχωρήσεων και δεν φέρουμε καμία ευθύνη για ανακρίβειες." },
		"Users should conduct their own due diligence before making transactions.": { "el" : "Οι χρήστες θα πρέπει να κάνουν τη δική τους έρευνα πριν από οποιαδήποτε συναλλαγή." },
		"Prohibited Activities": { "el" : "Απαγορευμένες Δραστηριότητες" },
		"You agree not to: Post false, misleading, or fraudulent property listings.": { "el" : "Συμφωνείτε να μην: Δημοσιεύετε ψευδείς, παραπλανητικές ή δόλιες καταχωρήσεις ακινήτων." },
		"Use Spitakimu for unlawful or unethical purposes.": { "el" : "Χρησιμοποιείτε το Spitakimu για παράνομους ή ανήθικους σκοπούς." },
		"Attempt to hack, disrupt, or compromise the website’s security.": { "el" : "Προσπαθείτε να παραβιάσετε, να διακόψετε ή να υπονομεύσετε την ασφάλεια της ιστοσελίδας." },
		"Copy, scrape, or redistribute content without authorization.": { "el" : "Αντιγράφετε, συλλέγετε ή αναδιανέμετε περιεχόμενο χωρίς άδεια." },
		"Privacy and Cookies": { "el" : "Πολιτική Απορρήτου και Cookies" },
		"Our Privacy Policy explains how we collect, use, and protect your data. ": { "el" : "Πολιτική Απορρήτου μας εξηγεί πώς συλλέγουμε, χρησιμοποιούμε και προστατεύουμε τα δεδομένα σας. " },
		"We use essential and analytical cookies to enhance user experience. By using our platform, you consent to our Cookie Policy.": { "el" : "Χρησιμοποιούμε βασικά και αναλυτικά cookies για να βελτιώσουμε την εμπειρία χρήσης. Χρησιμοποιώντας την πλατφόρμα μας, συναινείτε στην Πολιτική Cookies μας." },
		"Limitation of Liability": { "el" : "Περιορισμός Ευθύνης" },
		"Spitakimu provides its services 'as is' without warranties of any kind.": { "el" : "Το Spitakimu παρέχει τις υπηρεσίες του 'ως έχουν' χωρίς καμία εγγύηση οποιουδήποτε είδους." },
		"We are not responsible for any losses, damages, or disputes arising from transactions made through our platform.": { "el" : "Δεν φέρουμε καμία ευθύνη για τυχόν απώλειες, ζημιές ή διαφορές που προκύπτουν από συναλλαγές μέσω της πλατφόρμας μας." },
		"We do not own or sell any property listed on our website.": { "el" : "Δεν κατέχουμε ούτε πωλούμε οποιοδήποτε ακίνητο καταχωρημένο στην ιστοσελίδα μας." },
		"Third-Party Links and Services": { "el" : "Σύνδεσμοι και Υπηρεσίες Τρίτων" },
		"Our platform may contain links to third-party websites, which are not controlled by us.": { "el" : "Η πλατφόρμα μας μπορεί να περιέχει συνδέσμους προς ιστοσελίδες τρίτων, που δεν ελέγχονται από εμάς." },
		"We are not responsible for the content, policies, or practices of external sites.": { "el" : "Δεν φέρουμε ευθύνη για το περιεχόμενο, τις πολιτικές ή τις πρακτικές εξωτερικών ιστοσελίδων." },
		"Termination": { "el" : "Τερματισμός" },
		"We reserve the right to suspend or terminate user accounts that violate these Terms or engage in fraudulent activities.": { "el" : "Διατηρούμε το δικαίωμα να αναστείλουμε ή να τερματίσουμε λογαριασμούς χρηστών που παραβιάζουν αυτούς τους Όρους ή εμπλέκονται σε δόλιες δραστηριότητες." },
		"Modifications to Terms": { "el" : "Τροποποιήσεις στους Όρους" },
		"We may update these Terms from time to time. Changes will be effective upon posting. Continued use of our services constitutes acceptance of the updated Terms.": { "el" : "Ενδέχεται να ενημερώνουμε αυτούς τους Όρους κατά καιρούς. Οι αλλαγές θα ισχύουν από τη στιγμή που θα αναρτηθούν. Η συνεχιζόμενη χρήση των υπηρεσιών μας συνιστά αποδοχή των ενημερωμένων Όρων." },
		"Governing Law": { "el" : "Εφαρμοστέο Δίκαιο" },
		"These Terms are governed by the laws of Republic of Cyprus. Any disputes shall be resolved in the courts of Republic of Cyprus.": { "el" : "Αυτοί οι Όροι διέπονται από τη νομοθεσία της Κυπριακής Δημοκρατίας. Οποιεσδήποτε διαφορές θα επιλύονται στα δικαστήρια της Κυπριακής Δημοκρατίας." },
		"Contact Us": { "el" : "Επικοινωνία Μαζί Μας" },
		"If you have any questions about these Terms, please contact us at": { "el" : "Εάν έχετε οποιεσδήποτε ερωτήσεις σχετικά με αυτούς τους Όρους, παρακαλούμε επικοινωνήστε μαζί μας στο " },
		"By using Spitakimu, you acknowledge and agree to these Terms of Service.": { "el" : "Χρησιμοποιώντας το Spitakimu, αναγνωρίζετε και συμφωνείτε με αυτούς τους Όρους Παροχής Υπηρεσιών." },
	},
	"Validation": {
		"Username": { "el" : "Όνομα χρήστη" },
		"Email": { "el" : "Email" },
		"Full name": { "el" : "Ονοματεπώνυμο" },
		"Password": { "el" : "Κωδικός πρόσβασης" },
		"Confirm Password": { "el" : "Επιβεβαίωση κωδικού πρόσβασης" },
		"New Password": { "el" : "Νέος κωδικός πρόσβασης" },
		"Your full name": { "el" : "Το ονοματεπώνυμό σας" },
		"Your email": { "el" : "Το email σας" },
		"Subject": { "el" : "Θέμα" },
		"Your message": { "el" : "Το μήνυμά σας" },
		"Search area": { "el" : "Περιοχή αναζήτησης" },
		"Property type": { "el" : "Τύπος ακινήτου" },
		"Min beds": { "el" : "Ελάχιστα κρεβάτια" },
		"Max beds": { "el" : "Μέγιστα κρεβάτια" },
		"Min baths": { "el" : "Ελάχιστα μπάνια" },
		"Price range": { "el" : "Εύρος τιμών" },
		"Minimum price": { "el" : "Ελάχιστη τιμή" },
		"Maximum price": { "el" : "Μέγιστη τιμή" },
		"Bedrooms": { "el" : "Υπνοδωμάτια" },
		"Minimum beds": { "el" : "Ελάχιστα κρεβάτια" },
		"Maximum beds": { "el" : "Μέγιστα κρεβάτια" },
		"Bathrooms": { "el" : "Μπάνια" },
		"Minimum baths": { "el" : "Ελάχιστα μπάνια" },
		"Maximum baths": { "el" : "Μέγιστα μπάνια" },
		"Show all": { "el" : "Εμφάνιση όλων" },
		"Sort": { "el" : "Ταξινόμηση" },
		"Clear": { "el" : "Clear" },
		"Phone number": { "el" : "Αριθμός τηλεφώνου" },
		"Your message (optional)": { "el" : "Το μήνυμά σας (προαιρετικό)" },
		"Sale type": { "el" : "Τύπος πώλησης" },
		"You must agree to the terms of service and privacy policy": { "el" : "Πρέπει να συμφωνήσετε με τους Όρους Χρήσης και την Πολιτική Προσωπικών Δεδομένων." },
		"Name must be at least 2 characters": { "el" : "Το όνομα πρέπει να είναι τουλάχιστον 2 χαρακτήρες" },
		"Invalid email format": { "el" : "Μη έγκυρη μορφή email" },
		"Subject must be at least 3 characters": { "el" : "Το θέμα πρέπει να είναι τουλάχιστον 3 χαρακτήρες" },
		"Message must be at least 10 characters": { "el" : "Το μήνυμα πρέπει να είναι τουλάχιστον 10 χαρακτήρες" },
		"Error loading results count!": { "el" : "Σφάλμα!" },
		"Error loading results!": { "el" : "Σφάλμα φόρτωσης αποτελεσμάτων!" },
		"Please enter your message.": { "el" : "Παρακαλώ εισάγετε το μήνυμά σας." },
		"Message is too short": { "el" : "Το μήνυμα είναι πολύ μικρό" },
		"Message is too long": { "el" : "Το μήνυμα είναι πολύ μεγάλο" },
		"A user already exists with this email": { "el" : "Υπάρχει ήδη ένας χρήστης με αυτό το email" },
		"Password is too common": { "el" : "Ο κωδικός πρόσβασης είναι πολύ συνηθισμένος" },
		"No user exists with this username or email": { "el" : "Δεν υπάρχει χρήστης με αυτό το όνομα χρήστη ή email" },
		"Invalid username or password": { "el" : "Μη έγκυρο όνομα χρήστη ή κωδικού πρόσβασης" },
		"A user must be logged in to save a search": { "el" : "Πρέπει να είσαι συνδεδεμένος για να αποθηκεύσεις μια αναζήτηση" },
		"A saved search with the same name already exists": { "el" : "Μια αποθηκευμένη αναζήτηση με το ίδιο όνομα υπάρχει ήδη" },
		"A user already exists with this username": { "el" : "Ένας χρήστης υπάρχει ήδη με αυτό το όνομα χρήστη" },
		"The passwords must match": { "el" : "Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν" },
		"Save changes": { "el" : "Αποθήκευση αλλαγών" },
		"Select city": { "el" : "Επιλέξτε πόλη" },
	}
}