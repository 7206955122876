import React, { createContext, useContext, useState, type ReactNode } from 'react';
import { translations } from '#app/locales/translations.ts';

type SupportedLanguages = 'en' | 'el';
const supportedLanguages:SupportedLanguages[] = ['en', 'el']

interface LangContextProps {
  currentLang: string;
  t: (page:string, key:string) => string;
  switchLang: (newLang: SupportedLanguages ) => void;
  isEnglish: boolean
}

const LangContext = createContext<LangContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ language?:string, children: ReactNode }> = ({ language='en', children }) => {
  const [currentLang, setCurrentLang] = useState<SupportedLanguages>(language as SupportedLanguages);

  // Function to fetch translations
  const t = (page:string, key:string):string => {
    if (currentLang === 'en') return key;
    return translations[page] && translations[page][key] ? translations[page][key][currentLang] || key : key;
  };

  // Switch language function
  const switchLang = (newLang: SupportedLanguages) => {
    if (!supportedLanguages.includes(newLang)) return;

    setCurrentLang(newLang);

    // const loc = location.pathname.replace(/^\/(en|el)(?=\/|$)/, "");
    // await fetcher.submit(null, {
    //   method: 'POST',
    //   action: `/api/change-language/${newLang}`,
    // })
    // await navigate(`/${newLang}${loc}${location.search}`)
  };

  const isEnglish = currentLang === 'en'

  return (
    <LangContext.Provider value={{ currentLang, t, switchLang, isEnglish }}>
      {children}
    </LangContext.Provider>
  );
};

export const useLanguage = (): LangContextProps => {
  const context = useContext(LangContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const translate = (page:string, key:string, lang:string):string => {
  if (lang === 'en') return key;
  return translations[page] && translations[page][key] ? translations[page][key][lang] || key : key;
};
